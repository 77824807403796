<template>
  <div class="container-custom section-product pb-5 d-flex" style="height: calc(100vh - 70px)">
    <div class="d-flex justify-content-center  my-2 w-100">
      <div class="d-block position-relative">
        <div class="row g-3 mb-2" id="contact">
          <div class="col-12">
            <div class="text-center mb-5">
              <h3 class="title-page">Contact Us</h3>
            </div>
          </div>
        </div>
        <div class="d-block w-100 mt-5">
          <div class="d-flex justify-content-center align-items-center">
            <div
              class="img-container d-block position-relative"
              style="min-width: 280px; max-width: 500px"
            >
              <img
                src="../assets/img/contact-us.png"
                class="mx-auto d-block w-100"
                alt="img"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button
              type="button"
              class="btn btn-orange mt-5"
              @click="showModal = true"
            >
              Talk to us
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-contact-us :show="showModal" @close="closeModal" :form="form" />
  </div>
</template>

<script>
import modal from "./Modal.vue";
export default {
  name: "contact",
  components: {
    "modal-contact-us": modal,
  },
  data() {
    return {
      showModal: false,
      form: {
        name: null,
        email: null,
        message: null,
      },
    };
  },

  methods: {
    submitForm() {
      this.showModal = !this.showModal;
    },
    closeModal(payload) {
      this.showModal = false;

      if (
        payload.name != null &&
        payload.email != null &&
        payload.message != null
      ) {
        this.submitForm(payload);
      } else {
        this.form = {
          name: null,
          email: null,
          message: null,
        };
      }
    },
    openModal() {
      this.showModal = true;
    },
  },
};
</script>