<template>
  <div class="home-container">
    <div class="bg-gradient-color"></div>
    <div class="bg-img"></div>
    <div class="bg-overlay"></div>
    <div>
      <div
        class="
          d-flex
          position-relative
          justify-content-center
          align-items-center
          mx-4
        "
        style="height: 70vh"
      >
        <div class="container-custom">
          <div class="text-center mb-4">
            <h1 class="title text-white my-4">
              Selangor’s <br />
              Digital Solution Provider
            </h1>
            <p class="description m-auto fw-lighter text-white">
              SELGEM Sdn Bhd is a leading provider of digital technology
              services in Selangor, which includes system & infrastructure
              services encompassing integration and management of information
              technology and network infrastructure, the precision in the
              analysis, design and implementation makes the results in the
              highest levels of quality.
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex position-relative" style="height: 30vh">
        <div class="bg-triangle-left"></div>
        <div class="bg-triangle-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
};
</script>