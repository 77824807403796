<template>
    <div class="modal fade" id="policy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <img src="../assets/img/Polisi.jpg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'about'
}
</script>