<template>
  <div
    class="modal fade"
    id="contactUsModal"
    tabindex="-1"
    aria-labelledby="contactUsModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header align-items-center">
          <h5 class="modal-title" id="contactUsModalLabel">Contact Us</h5>
          <span @click="closeModal()" class="fs-4" style="cursor: pointer"
            ><i class="ri-close-line"
          /></span>
        </div>
        <div class="modal-body">
          <form ref="form" @submit.prevent="sendEmail">
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input class="form-control" type="text" name="from_name" />
            </div>
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input class="form-control" type="email" name="from_email" />
            </div>
            <div class="mb-3">
              <label class="form-label">Message</label>
              <textarea class="form-control" name="message"></textarea>
            </div>
            <div class="d-flex justify-content-end">
              <div class="btn btn-outline-secondary mr-2" @click="closeModal()">
                Cancel
              </div>
              <input class="btn btn-primary" type="submit" value="Send" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "modal-contact-us",
  props: ["show", "form"],
  watch: {
    show: {
      handler() {
        if (this.show) {
          $("#contactUsModal").modal("show");
        } else {
          $("#contactUsModal").modal("hide");
        }
      },
      immediate: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", this.form);
    },
    sendEmail() {
      emailjs
        .sendForm(
          "service_7ap2pea",
          "template_hme192h",
          this.$refs.form,
          "user_FUffrC4flF4y57xOaQrmb"
        )
        .then(
          (result) => {
            this.closeModal();
            console.log("SUCCESS!", result.text);
            Swal.fire({
              title: "Success!",
              text: "Thank you for reaching out. We will get back to you as soon as possible!",
              icon: "success",
              showConfirmButton: false,
            });
          },
          (error) => {
            console.log("FAILED...", error.text);
            Swal.fire({
              title: "Error!",
              text: error.text,
              icon: "error",
              showConfirmButton: false,
            });
          }
        );
    },
  },
};
</script>