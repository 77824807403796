<template>
  <div class="container-custom">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img
            src="../assets/img/selgemlogo.png"
            alt="selgemlogo"
            height="24"
            class="d-inline-block align-text-top"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-center navbar-custom"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
              style="cursor: pointer"
              data-toggle="modal" data-target="#policy"
              >Our Policy</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                style="cursor: pointer"
                @click="scroll('product')"
                >Services</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                style="cursor: pointer"
                @click="scroll('media')"
                >Media</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                style="cursor: pointer"
                @click="scroll('contact')"
                >Contact</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "navbar",
  methods: {
    scroll(refName) {
      var element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>