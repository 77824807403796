<template>
  <div class="container-custom section-product pb-5 d-flex" style="min-height: 100vh">
    <div class="d-flex justify-content-center align-items-center my-2">
      <div class="d-block">
        <div class="row g-3 my-5 align-items-center" >
          <div class="col-md-4" v-show="isMobile">
            <div class="">
              <div class="img-container d-block position-relative">
                <img
                  src="../assets/img/Surveillance/Taman.png"
                  class="mx-auto d-block w-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="">
              <h3 class="title-page" style="line-height: 1.2rem;">
                INTELLIGENT DRIVEN SECURITY & SURVEILLANCE
              </h3>
              <h2 style="line-height: 2rem;">
                Cutting-edge AI solutions tailored to your business needs.
              </h2>
              <p class="text-black-70 mt-4 card-text">
                Our solution is to fulfil all of your security and surveillance
                system needs for commercial, industrial and residential
                properties. Our AI assisted system begin by understanding your
                needs and objectives and assessing your current security system
                to customize the perfect security solutions.
              </p>
              <div class="d-flex justify-content-center mt-5 text-center">
                <div class="outline-box text-orange fs-small">RECON</div>
                <div class="outline-box text-orange fs-small">DETECT</div>
                <div class="outline-box text-orange fs-small">RESPOND</div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-show="!isMobile">
            <div class="">
              <div class="img-container d-block position-relative">
                <img
                  src="../assets/img/Surveillance/Taman.png"
                  class="mx-auto d-block w-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row g-3 my-5 align-items-center" >
          <div class="col-md-4">
            <div class="">
              <div class="img-container d-block position-relative">
                <img
                  src="../assets/img/Ai/ArtificialIntelegent.jpg"
                  class="mx-auto d-block w-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="">
              <h3 class="title-page" style="line-height: 1.2rem;">AI, MACHINE LEARNING & DEEP LEARNING</h3>
              <h2 style="line-height: 2rem;"> 
                Cutting-edge AI solutions tailored to your business needs.
              </h2>
              <p class="text-black-70 mt-4 card-text">
                Harness the power of our AI solutions to gain insights into your
                customers, competitors and your market. Whether it is machine
                learning, computer vision, chatbots, or something more advanced,
                our team of expert AI developers are ready to help you
                accelerate your business growth.
              </p>
            </div>
          </div>
        </div>
        <div class="row g-3 my-5 align-items-center" >
          <div class="col-md-5" v-show="isMobile">
            <div class="">
              <div class="img-container d-block position-relative">
                <img
                  src="../assets/img/Services/serviceGIS.png"
                  class="mx-auto d-block w-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="">
              <h3 class="title-page" style="line-height: 1.2rem;">GIS ONE STOP CENTER</h3>
              <h2 style="line-height: 2rem;">
                Discover, Measure and Monitor activity around the world to make
                better informed decisions.
              </h2>
              <p class="text-black-70 mt-4 card-text">
                IMUSS is an AI-powered Geospatial Analytics platform designed to
                simplify the use of location data, allowing users to query the
                world with three basic parameters
              </p>
              <div class="d-flex justify-content-center mt-5 text-center">
                <div class="outline-box border-0 text-orange fs-small">
                  <i class="ri-settings-2-line fs-3 text-dark"></i>
                  <p class="text-orange mb-0 fw-bold fs-6 lh-sm" style="line-height: 1.2rem;">WHAT</p>
                  <p class="text-orange lh-sm fw-lighter" style="line-height: 1.2rem;">Type of Activity</p>
                </div>
                <div class="outline-box border-0 text-orange fs-small">
                  <i class="ri-earth-line fs-3 text-dark"></i>
                  <p class="text-orange mb-0 fw-bold fs-6 lh-sm" style="line-height: 1.2rem;">WHERE</p>
                  <p class="text-orange lh-sm fw-lighter" style="line-height: 1.2rem;">On Earth</p>
                </div>
                <div class="outline-box border-0 text-orange fs-small">
                  <i class="ri-calendar-2-line fs-3 text-dark"></i>
                  <p class="text-orange mb-0 fw-bold fs-6 lh-sm" style="line-height: 1.2rem;">WHEN</p>
                  <p class="text-orange lh-sm fw-lighter" style="line-height: 1.2rem;">It Occurred</p>
                </div>
              </div>
              <!-- <div class="row justify-content-center text-center">
                <div class="col col-lg-3 col-sm-4">
                  <i class="ri-settings-2-line fs-3"></i>
                  <p class="text-orange mb-0 fw-bold fs-6 lh-sm">WHAT</p>
                  <p class="text-orange lh-sm fw-lighter">Type of Activity</p>
                </div>
                <div class="col col-lg-3 col-sm-4">
                  <i class="ri-earth-line fs-3"></i>
                  <p class="text-orange mb-0 fw-bold fs-6 lh-sm">WHERE</p>
                  <p class="text-orange lh-sm fw-lighter">On Earth</p>
                </div>
                <div class="col col-lg-3 col-sm-4">
                  <i class="ri-calendar-2-line fs-3"></i>
                  <p class="text-orange mb-0 fw-bold fs-6 lh-sm">WHEN</p>
                  <p class="text-orange lh-sm fw-lighter">It Occurred</p>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-md-5" v-show="!isMobile">
            <div class="">
              <div class="img-container d-block position-relative">
                <img
                  src="../assets/img/Services/serviceGIS.png"
                  class="mx-auto d-block w-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product2",
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if (window.screen.width < 500) {
      this.isMobile = true;
    }
  },
};
</script>