<template>
  <div class="bg-dark p-3">
    <div class="d-flex justify-content-center align-items-center">
      <img
        src="../assets/img/selgemlogo.png"
        alt="selgemlogo"
        height="24"
        class="d-inline-block align-text-top"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
};
</script>