<template>
  <div class="container-custom section-product pb-5 d-flex" style="min-height: 100vh">
    <div class="d-flex justify-content-center align-items-center my-2">
      <div class="d-block position-relative">
        <div class="row g-3 mt-2 mb-2" id="media">
          <div class="col-12" >
            <div class="text-center mb-5">
              <h5 class="title-page">Media</h5>
              <h2>Media Releases</h2>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-3">
            <div class="overflow-hidden">
              <img
                src="../assets/img/Media/GWF-Award-banner-003.jpg"
                class="mx-auto d-block w-100 mb-3"
                alt="img"
              />
            </div>
          </div>
          <div class="col-md-9">
            <div>
              <h5>PRESS RELEASE: 15th Dec 2020</h5>
              <p class="fw-lighter text-black-70 card-text">
                Selgem wins international geospatial award 2020 Selangor
                Geospatial Management System (Selgem) received international
                recognition when it won the Geospatial World Excellence Award in
                the State Governance category at the recent Geospatial World
                Awards 2020. This set Selgem as the local geospatial centre for
                identifying, planning and controlling of areas for development
                of affordable housing, solid waste management, drainage and
                river flow, forestry and agriculture, disaster management and
                development of telecommunication infrastructure and utilities
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="overflow-hidden">
              <img
                src="../assets/img/Media/15dis2020-selgem-anugerah-geospatial-antarabangsa.jpg"
                class="mx-auto d-block w-100 mb-3"
                alt="img"
              />
            </div>
          </div>
          <div class="col-md-9">
            <div>
              <h4>PRESS RELEASE : 13th June 2019</h4>
              <p class="fw-lighter text-black-70 card-text">
                SELGEM aims to develop a comprehensive database of Geospatial
                data of Selangor, which will be useful for a wide range of
                applications such as disaster management, environmental
                protection, utility mapping, agriculture and plantation
                management, river management, and many more to support the Smart
                Selangor and sustainable development initiatives in the state.
                SELGEM is expected to contribute towards a comprehensive
                development planning as well as greater efficiencies in
                Selangor’s land use.
              </p>
              <p class="fw-lighter text-black-70 card-text">
                Selangor believes that focus on sustainability is key for a more
                inclusive development, and through a centralised Geospatial
                management system, all the relevant government agencies,
                authorities, private sector and civil society stakeholders would
                be able to access accurate and comprehensive Geospatial data in
                a timely manner for planning, decision-making, monitoring and
                enforcement.
              </p>
              <p class="fw-lighter text-black-70 card-text">
                The perspective of foreign investment is also addressed through
                the availability of up-to-date and relevant information, which
                would give Selangor an important edge when investors identify
                and select appropriate industry to be developed in the state.
                Ultimately, the SELGEM Project will help the well-being of
                Rakyat through improved environmental safety and health, along
                with many other significant benefits to Selangor
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "media",
};
</script>