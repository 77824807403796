<template>
  <div id="app">
    <Navbar />
    <Home />
    <About />
    <Product />
    <Product2 />
    <Media />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Home from "./components/Home.vue";
import About from "./components/About.vue";
import Product from "./components/Product.vue";
import Product2 from "./components/Product2.vue";
import Media from "./components/Media.vue";
import Footer from "./components/Footer.vue";
import Contact from "./components/Contact.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Home,
    About,
    Product,
    Product2,
    Media,
    Contact,
    Footer,
  },
};
</script>
