<template>
  <div class="container-custom section-product pb-5 d-flex" style="min-height: 100vh">
    <div class="d-flex justify-content-center my-2">
      <div class="row g-3 mt-5 mb-2">
        <div class="col-12" id="product">
          <div class="text-center m-5">
            <h3 class="title-page">Products</h3>
            <h2>We Offers Range of Services</h2>
          </div>
        </div>
        <div
          class="col-md-3 col-sm-3"
          v-for="(product, idx) in products"
          :key="idx"
        >
          <div
            class="card custom-card border-1 overflow-hidden bg-white mb-2"
            :style="
              idx + 1 != products.length && !isMobile
                ? `margin-top: ${idx * 80}px;`
                : ''
            "
          >
            <div
              class="d-block position-relative overflow-hidden"
              style="max-height: 180px"
            >
              <img
                :src="product.img"
                class="card-img-top"
                :alt="product.title"
              />
              <img
                v-if="product.img2 != null"
                :src="product.img2"
                class="card-img-top img-floating"
                :alt="product.title"
              />
            </div>
            <div class="card-body text-center">
              <h5 class="card-title">{{ product.title }}</h5>
              <p class="card-text">{{ product.desc }}</p>
              <p v-if="product.desc2 != null" class="card-text fw-lighter text-black-70">
                {{ product.desc2 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "../assets/img/Services/Contourline.png";
import img11 from "../assets/img/GIS/Contour.png";
import img2 from "../assets/img/Services/DaaS.png";
import img3 from "../assets/img/Services/DigitalSolution.jpg";
import img4 from "../assets/img/Services/Surveillance.png";

export default {
  name: "product",
  data() {
    return {
      isMobile: false,
      products: [
        {
          img: img1,
          img2: img11,
          title: "GIS One Stop Center",
          desc: "As a leading Geospatial one-stop centre in Selangor, we offer GIS database management, analysis, and Web-GIs services.",
          desc2: null,
        },
        {
          img: img2,
          img2: null,
          title: "SaaS & DaaS",
          desc: "Provide drone services and integration with various online platforms to make it simple and customizable to meet the needs of the client.",
          desc2:
            "Emphasize the importance of close loop integration from data capture to the analytical process.",
        },
        {
          img: img3,
          img2: null,
          title: "Digital Solution Provider",
          desc: "As a leading digital solution provider, we integrate IOT, AI, and cutting-edge technology to create new innovative, informative platforms in line with Digital IR4.0",
          desc2: null,
        },
        {
          img: img4,
          img2: null,
          title: "Security & Surveillance",
          desc: "Integration of various technologies and tools with customised AI systems in creating a safe environment by providing solutions such as urban surveillance, asset inspection, and integrated monitoring systems with the highest data security system.",
          desc2: null,
        },
      ],
    };
  },
  mounted() {
    if (window.screen.width < 500) {
      this.isMobile = true;
    }
  },
};
</script>
