import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'; 

import './assets/css/main.scss'
import 'remixicon/fonts/remixicon.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
